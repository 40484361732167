// ql editor
.ql-editor {
  height: auto !important;
  width: 100% !important;
  a {
    position: relative;
    display: inline-block;
  }
}

.ql-container {
  display: flex !important;
}