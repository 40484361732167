.specto-drag-sort {
  thead {
    display: none;
  }

  tr {
    background: transparent !important;
  }

  td {
    border: none !important;
  }
}
